import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, OnDestroy, OnInit } from '@angular/core';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { Observable, Subject, filter, firstValueFrom, map, takeUntil } from 'rxjs';
import { SESSION_STORAGE_KEYS } from '../session_storage/constants/session-storage-keys.constant';
import { InteractionStatus, PublicClientApplication, RedirectRequest } from '@azure/msal-browser';
import { UserDTO } from 'app/core/api/models/userDTO';
import { authConfig } from './msal.factory';

@Injectable({
  providedIn: 'root',
})
export class AuthUserService implements OnInit, OnDestroy {
  private readonly _destroying$ = new Subject<void>();

  isFrame = false;

  loginDisplay = false;

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private http: HttpClient,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
  ) {}

  ngOnInit(): void {
    this.isFrame = window !== window.parent && !window.opener;
    this.setLoginDisplay();

    this.authService.instance.enableAccountStorageEvents();
  }

  setLoginDisplay(): void {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  checkAndSetActiveAccount(): void {
    let activeAccount = this.authService.instance.getActiveAccount();
    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  getStatus(): Observable<string> {
    return this.msalBroadcastService.inProgress$.pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None),
      takeUntil(this._destroying$),
    );
  }

  loginRedirect(): void {
    // Check if the user is already logged in
    this.setLoginDisplay();
    if (this.loginDisplay) {
      return;
    }

    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  async switchToAnotherTenant(newTenant: string): Promise<void> {
    localStorage.removeItem(SESSION_STORAGE_KEYS.TENANT_ID);
    localStorage.setItem(SESSION_STORAGE_KEYS.TENANT_ID, newTenant);

    authConfig.auth.authority = `https://login.microsoftonline.com/${newTenant}/`;

    this.authService.instance = new PublicClientApplication(authConfig);

    await this.authService.instance.initialize();

    var activeAccount = this.authService.instance.getActiveAccount() ?? this.authService.instance.getAllAccounts()[0];

    activeAccount.tenantId = newTenant;
    activeAccount.tenantProfiles = new Map<string, any>();

    try {
      await firstValueFrom(
        this.authService.acquireTokenSilent({
          scopes: ['api://c08e2c98-a937-4642-837e-2105b3296830/api.scope'],
          forceRefresh: true,
          authority: 'https://login.microsoftonline.com/' + newTenant + '/',
        }),
      );
    } catch (error: any) {
      this.authService.loginRedirect({
        scopes: ['api://c08e2c98-a937-4642-837e-2105b3296830/api.scope'],
        authority: 'https://login.microsoftonline.com/' + newTenant + '/',
      });
    }
  }

  logout(): void {
    localStorage.removeItem(SESSION_STORAGE_KEYS.TENANT_ID);
    localStorage.removeItem('currentUser');
    this.authService.logout();
  }

  getAadProfilePicture(): Observable<any> {
    return this.http.get('https://graph.microsoft.com/v1.0/me/photo/$value', {
      responseType: 'blob',
      headers: new HttpHeaders({ 'Content-Type': 'image/jpeg' }),
    });
  }

  getAadProfilePictureForUser(user: UserDTO): Observable<any> {
    if (user !== null && typeof user !== 'undefined') {
      let srcString: string = user.profilePicture.toString();
      return this.http
        .get(srcString, {
          responseType: 'blob',
          headers: new HttpHeaders({ 'Content-Type': 'image/jpeg' }),
        })
        .pipe(
          map((res: any) => {
            return res;
          }),
        );
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
  }
}
