import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MsalService } from '@azure/msal-angular';
import { PublicClientApplication } from '@azure/msal-browser';
import { retry } from 'rxjs';
import { MessageService } from 'primeng/api';
import { environment } from '../environments/environment';
import { setMsalTenantId } from './core/auth/msal.factory';
import { AuthorizationService } from './core/authorization/services/authorization.service';
import { AuthUserService } from './core/auth/auth-user.service';
import { SCOPES } from './core/authorization/constants/scopes.constant';
import { SESSION_STORAGE_KEYS } from './core/session_storage/constants/session-storage-keys.constant';
import { ConfigService } from './core/config/config.service';
import { AzureTenant } from './shared/models/azureTenant';
import { ConnectedTenants } from './shared/models/connectedTenants';
import { TenantResponse } from './shared/models/tenantResponse';
import { ToastService } from '@shared/services/toast/toast.service';
import { DataService } from '@shared/services/data/data.service';
import { ThemeService } from '@shared/services/theme/theme.service';
import { AvailableThemes } from '@shared/services/theme/types/available-themes.type';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  isTenantSwitched = false;
  isNoTenantConnected = false;
  isConsent = false;
  isOnboarding = false;
  connectedTenants: AzureTenant[] = [];
  selectedTenantId: string | undefined;

  constructor(
    private msalService: MsalService,
    private httpClient: HttpClient,
    private auth: AuthUserService,
    private authorizationService: AuthorizationService,
    private readonly configService: ConfigService,
    private readonly themeService: ThemeService,
    private toastMessageService: ToastService,
    private messageService: MessageService,
    private dataService: DataService,
  ) {}

  ngOnInit(): void {
    const storedTenantId = localStorage.getItem(SESSION_STORAGE_KEYS.TENANT_ID);
    this.isOnboarding = sessionStorage.getItem(SESSION_STORAGE_KEYS.ONBOARDING_ACTIVE) !== null;

    this.toastMessageService.getToastMessageObs().subscribe({
      next: (message) => {
        if (message) {
          this.messageService.add(message);
        }
      },
    });

    if (storedTenantId) {
      this.selectedTenantId = storedTenantId;
      setMsalTenantId(storedTenantId);
      this.isTenantSwitched = true;
      this.getTokenAfterLogin(storedTenantId);
      this.loadConfig();
    } else {
      if (!this.checkIfConsent()) {
        this.auth.loginRedirect();
        this.auth.getStatus().subscribe(async (status) => {
          this.loadConfig();
          await this.manageTenantRedirect();
        });
      }
    }
  }

  private loadConfig(): void {
    this.configService.getOrganizationConfig().subscribe({
      next: (result) => {
        if (result) {
          this.themeService.setLightTheme(result.lightTheme as AvailableThemes);
          this.themeService.setDarkTheme(result.darkTheme as AvailableThemes);
        }
      },
    });
  }

  checkIfConsent(): boolean {
    let currentUrl = window.location.href;
    let consentRoute = environment.frontendUrl + '/consent';
    if (currentUrl.substring(0, consentRoute.length) === consentRoute) {
      this.isConsent = true;
      return true;
    }
    return false;
  }

  async manageTenantRedirect(): Promise<void> {
    let onboarding = await this.checkIfOnboarding();

    if (!onboarding) {
      this.httpClient
        .get<TenantResponse>('https://management.azure.com/tenants?api-version=2022-12-01')
        .pipe(retry(3))
        .subscribe({
          next: (tenantResponse: TenantResponse) => {
            let azureTenants: AzureTenant[] = tenantResponse.value;
            this.httpClient
              .put<ConnectedTenants>(environment.baseUrl + 'tenants/connected', { tenants: azureTenants })
              .subscribe({
                next: (connectedTenants: ConnectedTenants) => {
                  this.handleConnectedTenants(connectedTenants);
                },
              });
          },
        });
    }
  }

  async checkIfOnboarding(): Promise<boolean> {
    let currentUrl = window.location.href;
    if (currentUrl.includes('onboarding')) {
      sessionStorage.setItem(SESSION_STORAGE_KEYS.ONBOARDING_ACTIVE, 'true');
      this.isOnboarding = true;
      if (localStorage.getItem(SESSION_STORAGE_KEYS.TENANT_ID) != null) {
        let tenantId = localStorage.getItem(SESSION_STORAGE_KEYS.TENANT_ID)!;
        localStorage.removeItem(SESSION_STORAGE_KEYS.TENANT_ID);
        await this.switchTenant(tenantId);
      } else {
        this.isTenantSwitched = true;
      }
      return true;
    }
    return false;
  }

  async switchTenant(tenant: string): Promise<void> {
    const isIE =
      window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
    this.msalService.instance = new PublicClientApplication({
      auth: {
        clientId: environment.clientId,
        authority: 'https://login.microsoftonline.com/' + tenant,
        redirectUri: window.location.origin,
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: isIE,
      },
    });

    setMsalTenantId(tenant);

    this.msalService
      .acquireTokenRedirect({
        scopes: ['https://management.azure.com/user_impersonation'],
        authority: 'https://login.microsoftonline.com/' + tenant,
      })
      .subscribe({
        next: () => {
          this.isTenantSwitched = true;
        },
      });

    await this.msalService.instance.initialize();
  }

  async handleConnectedTenants(connectedTenants: ConnectedTenants): Promise<void> {
    this.connectedTenants = connectedTenants.tenants;
    if (this.connectedTenants.length == 0) {
      this.isNoTenantConnected = true;
      this.logout();
    } else if (this.connectedTenants.length == 1) {
      // Set the tenant name to the default tenant
      this.dataService.setCurrentTenantName(this.connectedTenants[0].displayName);
      if (this.msalService.instance.getAllAccounts()[0].tenantId.includes(this.connectedTenants[0].tenantId)) {
        setMsalTenantId(this.connectedTenants[0].tenantId);
        this.getTokenAfterLogin(this.connectedTenants[0].tenantId);
      } else {
        await this.auth.switchToAnotherTenant(this.connectedTenants[0].tenantId);
        this.getTokenAfterLogin(this.connectedTenants[0].tenantId);
      }
    } else {
      let tenantIdInStorage = localStorage.getItem(SESSION_STORAGE_KEYS.TENANT_ID);
      if (tenantIdInStorage == undefined) {
        // No stored tenant, prompt for selection
      } else {
        setMsalTenantId(tenantIdInStorage);
        // Set the tenant name to the previously selected tenant
        this.dataService.setCurrentTenantName(
          this.connectedTenants.find((t) => t.tenantId == tenantIdInStorage).displayName,
        );
        this.getTokenAfterLogin(tenantIdInStorage);
      }
    }
  }

  // Get token without user interaction
  getTokenAfterLogin(tenantId: string): void {
    this.auth.getStatus().subscribe((status) => {
      this.authorizationService
        .acquireToken([SCOPES.USER_IMPERSONATION], `https://login.microsoftonline.com/${tenantId}/`)
        .subscribe({
          next: () => {
            var newAccount = this.msalService.instance.getAllAccounts().find((a) => a.tenantId == tenantId);

            if (newAccount != undefined) {
              this.msalService.instance.setActiveAccount(newAccount);
            }

            this.isTenantSwitched = true;
          },
        });
    });
  }

  logout(): void {
    localStorage.removeItem(SESSION_STORAGE_KEYS.TENANT_ID);
    localStorage.removeItem('currentUser');
    this.msalService.logout();
  }

  // Method to handle tenant selection
  async selectTenant(id: string) {
    if (id != undefined) {
      this.dataService.setCurrentTenantName(this.connectedTenants.find((t) => t.tenantId == id).displayName);
      await this.auth.switchToAnotherTenant(id);
      setMsalTenantId(id);
      this.getTokenAfterLogin(id);
    }
  }
}
